@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/settings";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
$smartdp-search-marketing-color: $black !default;

.smartdp-search {
	.dynamic-blocks-renderer {
		&__item {
			& > :not(.smartdp-banner-flashsale--immersive) {
				@include grid-container;
				padding: 30px 15px;

				@include gridle_state(md) {
					padding: 40px 0;
				}
			}
			& > .multi-blocks {
				padding: 0;
			}
		}
	}

	.multi-blocks {
		width: initial;
		.dynamic-blocks-renderer__item {
			& + .dynamic-blocks-renderer__item {
				& > div:not(.smartdp-banner-flashsale--immersive) {
					// reduire espace entre 2 blocks dans un multi-block
					padding-top: 10px;

					@include gridle_state(md) {
						padding-top: 0;
					}
				}
			}
		}
	}

	.dynamic-blocks-renderer--alternate-colors {
		& > .dynamic-blocks-renderer__item:nth-child(odd) {
			background: white;
		}

		& > .dynamic-blocks-renderer__item:nth-child(even) {
			background: $grey-light;
		}
	}

	em {
		font-style: italic;
	}

	&__hero {
		position: relative;
		height: 210px;

		@include gridle_state(md) {
			height: 396px;

			&-content {
				position: absolute;
				left: 0;
				right: 0;
				height: 100%;
				top: 0;
				bottom: 0;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
			}
		}
	}

	&__marketing {
		color: white;
		text-shadow: 0 4px 7px rgba(0, 0, 0, 0.5);
		line-height: 1;

		@include gridle_state(md) {
			display: flex;
			width: 100%;
			justify-content: center;
		}
	}

	&__marketing-container {
		position: absolute;
		height: 130px;
		bottom: 0;
		left: 0;
		right: 0;
		background-image: linear-gradient(
			to bottom,
			rgba(51, 51, 51, 0),
			$smartdp-search-marketing-color 100%
		);
		padding-left: 10px;
		padding-right: 10px;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		padding-bottom: 15px;

		@include gridle_state(md) {
			background: none;
			height: auto;
			bottom: initial;
			width: 1024px;
			align-items: center;
			position: initial;
		}
	}

	&__title {
		@extend %font-bold;
		font-style: italic;
		color: white;
		font-size: 2.8rem;
		letter-spacing: -1.5px;
		text-shadow: 0 4px 7px rgba(0, 0, 0, 0.5);
		line-height: 1;

		@include gridle_state(md) {
			text-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
			font-size: 4.2rem;
			line-height: 1.1;
		}
	}

	&__subtitle {
		color: white;
		font-size: 1.5rem;
		letter-spacing: -1.2px;
		text-shadow: 0 4px 7px rgba(0, 0, 0, 0.5);

		@include gridle_state(md) {
			text-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
			font-size: 1.9rem;
		}
	}

	&__form {
		padding: 0 10px 20px;
		background: $color-primary;
		width: calc(100% - 20px);

		@include gridle_state(md) {
			border-radius: $border-radius-big;
			box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
			padding: 5px 10px;
			background: white;
			width: calc(1024px - 20px);
		}
	}

	@include gridle_state(md) {
		position: relative;
	}
}
